import { Col, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Account, BankAccounts, capitalize, moneyAmount } from '../../types';
import { LoadingSpinnerComponent } from '../Loading';
import { UserBankAccount } from './BankAccount';

interface IBankAccountSelector {
  setSelectedAccount: (account: Account , view:string) => void;
  accounts?: Array<Account>;
  selectedAccounts?:  Array<Account>;
}
interface IUserBankConnectionProps {
  userId?: string;
  view: string;
  filter?: string[];
  bankAccountContext: IBankAccountSelector;
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const UserBankConnections = ({ view, filter, bankAccountContext }: IUserBankConnectionProps) => {
  const accounts = bankAccountContext.accounts;
  
  return (
    <>
      {bankAccountContext && accounts && (
        <>
        <Row>
          <Col>
            <h4>Bank Accounts</h4>
          </Col>
        </Row>
        <Carousel responsive={responsive} containerClass="container-padding-bottom" >
          { accounts.filter(f=> filter === undefined || filter?.includes(f.subType.toLowerCase())).map((account: Account)=>(
            <UserBankAccount 
              key={account.id}
              account={account}
              view={view}
              bankAccountContext={bankAccountContext}/>
          ))}
        </Carousel>
        </>
      )}     
   </>
  );
};

export { UserBankConnections };

