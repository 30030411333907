import { useState, useContext, useCallback, useMemo, useEffect } from "react";
import { Row } from "react-bootstrap";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  MapControl,
  Marker,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import styled from "styled-components";
import { LoadingSpinnerComponent } from "../Loading";
import { env } from "process";

type TypePosition = {
  lat: number;
  lng: number;
};

type MapLocationProps = {
  address: string;
};

function MapLocation({ address }: MapLocationProps) {
  const map = useMap();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [center, setCenter] = useState<TypePosition>({
    lat: 38.0,
    lng: -97.0,
  });
  const [zoom, setZoom] = useState<number>(2.5);

  const geocodingLib = useMapsLibrary("geocoding");
  const geocoder = useMemo(
    () => geocodingLib && new geocodingLib.Geocoder(),
    [geocodingLib]
  );

  useEffect(() => {
    if (!geocoder) return;
    setIsLoaded(true);
    if (address !== "") {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          setCenter({
            lat: results?.[0]?.geometry?.location.lat() || 38.0,
            lng: results?.[0]?.geometry?.location.lng() || -97.0,
          });
          setZoom(11);
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  }, [geocoder]);

  return (
    isLoaded ?
    <>
      <Row
        style={{
          marginTop: "24px",
          marginBottom: "24px",
          height: "200px",
          position: "relative",
        }}
      >
        <Map
          defaultCenter={center}
          center={center}
          defaultZoom={5}
          zoom={zoom}
        >
          <Marker position={center} />
        </Map>
        {zoom !== 11 && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#dc3545" }}>
            Unable to locate contact address
          </p>
        </div>
      )}
      </Row>
      
    </> : <LoadingSpinnerComponent></LoadingSpinnerComponent>
  );
}

const MapParent = ({ address }: MapLocationProps) => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY!}>
      <MapLocation address={address} />
    </APIProvider>
  );
};

export { MapParent as MapLocation };
