/* eslint-disable no-useless-escape */

import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { endOfWeek, add, differenceInDays, startOfMonth, endOfMonth, differenceInMonths, format } from 'date-fns';
import jwt_decode from 'jwt-decode';

export type TwoFactorRequiredResponse = { 
  succeeded: boolean;
  twoFactorPhone: string;
  requiresTwoFactor: boolean;
}

export type AuthResponse = { 
  succeeded: boolean;
  accessToken: string;
  refreshToken: string;
}

export const getAppUserFromAuth = (response: AuthResponse) => { 
  const tokenData: any = jwt_decode(response?.accessToken);

        const appUser: AppUser = {
          id: tokenData?.Id,
          name: tokenData?.unique_name,
          lastName: tokenData?.family_name,
          token: response?.accessToken,
          refreshToken: response?.refreshToken,
          clientId: tokenData?.CentrexClientId,
          exp: tokenData?.exp,
          email: tokenData.email,
          role: tokenData?.role,
          mobilePhone: tokenData?.['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone']
        };
        return appUser;
}

export interface AppUser  {
  name: string;
  lastName: string;
  token: string;
  refreshToken: string;
  clientId: string;
  email: string;
  mobilePhone: string;
  id: string;
  exp: string;
  role: string | Array<string>;
};

export type WebUser = {
  email: string;
  firstName?: string;
  lastName?: string;
  clientId?: string;
  id: string;
  mobilePhone?: string;
  roles: string | Array<string>;
  profileImage?: string;
};

export interface CreateWebUserForm extends Omit<WebUser, 'id'> {
  password: string;
  id?: string;
}

export type UsersStats = {
  totalUsers: string;
  plaidUsers: string;
  deactiveUsers: string;
  plaidErrors: string;
};

export type UsersSalesStats = {
  totalUsers: string;
  newUsersToday: string;
  newUsersWeek: string;
  newUsersMonth: string;
  newUsersYear: string;
};

export type BaseStats = {
  newUsers: number;
  deletedUsers: number;
  totalUsers: number;
  newPlaidConnections: number;
  totalPlaidConnections: number;
  removedPlaidConnections: number;
  plaidAssetReport: number;
  notifications: number;
  totalDisconnectedPlaidItems: number;
};

export type MonthStatsResponse = {
  id: string;
  clientId: string;
  days: Array<DailyStats>;
  month: MonthlyStats;
  startDate: Date;
  endDate: Date;
};

export type DailyStatsResponse = {
  day: string;
  deletedUsers: number;
  newPlaidConnections: number;
  newUsers: number;
  notifications: number;
  plaidAssetReport: number;
  removedPlaidConnections: number;
  totalDisconnectedPlaidItems: number;
  totalPlaidConnections: number;
  totalUsers: number;
};

export type MonthlyStats = BaseStats & {
  dateKey: string;
  clientId: string;
};

export type LatestUsersStats = {
  clientId: string;
  thisYear: number;
  lastWeek: number;
  lastMonth: number;
  today: number;
};
export type DailyStats = BaseStats & {
  day: string;
};

export type UsersSubscriptionsDataPoint = {
  date: Date;
  membership: number;
  connection: number;
};

export type Account = {
  type: string;
  id: string;
  number: string;
  subType: string;
  name: string;
  institutionName: string;
  accessToken: string;
  requiresLogin: boolean;
  bankId: string;
  itemId: string;
  createdOn: string;
  institutionId: string;
  latestBalance: string;
  lastSyncDate: string;

};

export type BankAccounts = {
  clientId: string;
  id: string;
  institutionId: string;
  institutionLogo: string;
  institutionName: string;
  itemId: string;
  createdOn: string;
  removedOn: string;
  requireAuth: boolean;
  userId: string;
  accessToken: string;
  accounts: Array<Account>;
};

export type BaseClient = {
  id: string;
  clientId: string;
  created?: Date;
};

// Client Types
export type ClientBilling = BaseClient & {
  stripeCustomerId?: string;
  isTestAccount?: boolean;
};

export type ClientInfo = {
  clientName: string;
  subdomain: string;
  website?: string | null;
  appStoreLink?: string | null;
  playStoreLink?: string | null;
  address?: string | null;
  phone?: string | null;
  billingEmail: string;
};

export type CrmUser = {
  clientId: string;
  crmUserId: string;
};

export type CrmCredentials = {
  crmClientSecret: string;
  crmKeyId: string;
  crmUserId?: string;
  clientId: string;
  isTestClient: boolean;
  crmUrl: string;
};

export type ClientAssets = {
  logo?: string;
  icon?: string;
  primaryColor?: string;
  secondaryColor?: string;
  whiteLogo?: string;
  whiteIcon?: string;
};

export interface ClientEmailSettings {
  emailFrom?: string;
  nameFrom?: string;
  sendgridApiKey?: string;
  sendgridApiEnabled: boolean;
}

export type ClientCentrexConfig = BaseClient &
  CrmCredentials & {
    crmUrlRedirect?: string;
    isActive?: boolean;
  };

export type Client = ClientAssets & ClientInfo & ClientEmailSettings & ClientCentrexConfig & ClientBilling;

export type User = {
  id: string;
  userName: string;
  clientId: string;
  contactId: string;
  userStatus: number;
  createdDate: Date;
  company: string;
  plaidConnections: number;
  plaidAuthRequired: boolean;
  email: string;
  profileImageURL: string;
  pushEnabled: boolean;
  firstName: string;
  lastName: string;
  mobileApp: boolean;
  wppUser: boolean;
};

export type UserData = {
  id: string;
  clientId: string;
  contactId: string;
  userStatus: number;
  createdDate: Date;
  company: string;
  accounts: Array<BankAccounts>;
  email: string;
  profileImageURL: string;
  pushEnabled: boolean;
  userName: string;
  mobileApp: boolean;
  wppUser: boolean;
};

export type Transaction = {
  id: string;
  amount: number;
  date: string;
  description: string;
  account: string;
};
export type ReconciliationTransaction = IncludeItem & Transaction;

export type PlaidError = {
  display_message: string;
  error_code: string;
  eror_message: string;
  error_type: string;
};

export type AccountSummary = {
  accountId: string;
  totalDeposits: string;
  avgBalance: string;
  withdrawals: string;
  startingBalance: string;
  endBalance: string;
  noDeposits: string;
  daysNegative: string;
  nsf: string;
  dateKey: string;
  balanceDays: Array<DayBalance>;
};

export type DayBalance = {
  dateTime: string;
  balance: number;
};

//CRM
export type Contact = {
  id: string;
  email: string;
  address: {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    zip: string;
  };
};

export type LocationProps = {
  state: {
    from: Location;
  };
};

export type formError = {
  field: string;
};

export type TableItem<T extends {}> = T & {
  number: number;
};

export type CardData = {
  number: string;
  expMonth: string;
  expYear: string;
  cVC: string;
  clientId: string;
};

export type CardStored = {
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
};

export type InvoiceSettings = {
  custom_fields: string;
  default_payment_method: string;
  footer: string;
  rendering_options: string;
};

export type DropdownItem = {
  id: string;
  display: string;
};

export interface KeyValuePair {
  key: string;
  value: string;
}

export const formatUTCDate = (date: string) => {
  if (date) {
    const date2 = new Date(Date.parse(date));

    if (date2) {
      return formatDate(date2);
    }
  }

  return '';
};

export const getFirstAndLastDayOfWeek = (date: Date) => {
  const currDate = date;
  const currentWeekDay = currDate.getDay();
  const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
  const wkStart = new Date(new Date(currDate).setDate(currDate.getDate() - lessDays));
  const wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));

  return { weekStart: wkStart, weekEnd: wkEnd };
};

export const getFirstAndLastDayofMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  return { firstDay: new Date(year, month, 1), lastDay: new Date(year, month + 1, 0) };
};

export const getWeekByWeek = (startDate: Date, endDate: Date) => {
  let tmpStart: Date = startDate;
  const arrDate: Array<{ startDate: string; endDate: string }> = [];
  const totalDay = differenceInDays(endDate, startDate);
  const totalWeeks = totalDay % 7 === 0 ? totalDay / 7 : Math.floor(totalDay / 7) + 1;

  for (let index = 0; index < totalWeeks; index++) {
    const tmpEnd = endOfWeek(tmpStart);
    arrDate.push({
      startDate: `${tmpStart.toISOString().split('T')[0]}T00:00:00Z`,
      endDate:
        tmpEnd >= endDate
          ? `${endDate.toISOString().split('T')[0]}T00:00:00Z`
          : `${tmpEnd.toISOString().split('T')[0]}T00:00:00Z`,
    });

    tmpStart = add(tmpEnd, { days: 1 });
  }

  if (tmpStart < endDate) {
    arrDate.push({
      startDate: `${tmpStart.toISOString().split('T')[0]}T00:00:00Z`,
      endDate: `${endDate.toISOString().split('T')[0]}T00:00:00Z`,
    });
  }

  return arrDate;
};

export const getMonthByMonth = (startDate: Date, endDate: Date) => {
  let tmpStart: Date = startOfMonth(startDate);
  const arrDate: Array<{ startDate: string; endDate: string }> = [];
  const totalMonth = differenceInMonths(endDate, startDate);

  for (let index = 0; index < totalMonth; index++) {
    const tmpEnd = endOfMonth(tmpStart);
    arrDate.push({
      startDate: `${format(tmpStart, 'yyyy-MM-dd')}T00:00:00Z`,
      endDate:
        tmpEnd >= endDate ? `${format(endDate, 'yyyy-MM-dd')}T00:00:00Z` : `${format(tmpEnd, 'yyyy-MM-dd')}T00:00:00Z`,
    });

    tmpStart = add(tmpEnd, { days: 1 });
  }

  if (tmpStart < endDate) {
    arrDate.push({
      startDate: `${format(tmpStart, 'yyyy-MM-dd')}T00:00:00Z`,
      endDate: `${format(endDate, 'yyyy-MM-dd')}T00:00:00Z`,
    });
  }

  return arrDate;
};

export const formatDate = (date: Date) => {
  if (date) {
    let month = date.getMonth() + 1;
    return (
      month.toString().padStart(2, '0') +
      '/' +
      date.getDate().toString().padStart(2, '0') +
      '/' +
      date.getFullYear().toString().slice(2, 4)
    );
  }
  return '';
};

// A little bit simplified version
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export const SendGridRegex = /^SG\.[\w_]{16,32}\.[\w_-]{16,64}$/;
export const isValidSendGridKey = (value: string | undefined) => {
  if(!value) return false;
  return !!SendGridRegex.test(value);
};

export const GUIDRegex =
  /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/;
export const isValidGUID = (value: string) => {
  return !!/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
    value
  );
};

export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email: string) => {
  if (email.length > 0) {
    const isEmailValid = EmailRegex.test(email); // use any validator you want
    return isEmailValid;
  }
  return false;
};

export const NumberRegex = /^[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]$/;
export const isValidNumber = (number: string, digits: number) => {
  if (number.length < digits) {
    return false;
  }
  return NumberRegex.test(number); // use any validator you want
};

export const UrlRegex = /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const isValidUrl = (urlString: string) => {
  return !!UrlRegex.test(urlString);
};
export const ImageFileRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/;
export const HexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const NoWhiteSpaceRegex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])$/;

export const Roles: Array<KeyValuePair> = [
  {
    key: '1',
    value: 'Admin',
  },
  {
    key: '2',
    value: 'Client',
  },
];

export type PusnNotification = {
  title: string;
  description: string;
};

export type SentPushNotifications = {
  clientId: string;
  userId: string;
  title: string;
  message: string;
  data: string;
  notificationType: number;
  pushNofiticationType: number;
  sendToAllClientUsers: boolean;
  sendToListOfUsers: boolean;
  sendToSpecificUser: boolean;
};

//Plaid

export enum PlaidItemErrorCode {
  LoginRequired = 'ITEM_LOGIN_REQUIRED',
}

//Asset Report
export type AssetReport = {
  id: string;
  clientId: string;
  userId: string;
  contactId: string;
  requestDate: string;
  status: number;
  assetReportToken: string;
  assetReportId: string;
};

export type ProcessLinkRequest = PlaidLinkOnSuccessMetadata & {
  clientId: string | undefined;
  userId: string | undefined;
};

export type StripeInvoice = {
  id: string;
  amount_due: string;
  amount_paid: string;
  due_date: string;
  invoice_pdf: string;
  period_end: string;
  period_start: string;
};

export type StripePrice = {
  id: string;
  type: string;
  unit_amount: number;
  billing_scheme: string;
  tiers: Array<StripeTier>;
};

export type StripeTier = {
  flat_amount: number;
  unit_amount: number;
  up_to: number;
  id: string;
};

export type StripeProduct = {
  id: string;
  name: string;
  description: string;
  price: StripePrice;
  app_product: string;
};

export type GetLinkTokenResponse = {
  link_token: string;
};

export const getUnitPrice = (product: StripeProduct, items: number) => {
  if (product.price.billing_scheme !== 'tiered') {
    return product.price.unit_amount;
  }
  if (product.price.billing_scheme === 'tiered') {
    let tiers = product.price.tiers;
    if (tiers.length === 1) {
      return tiers[0].unit_amount;
    }
    return tiers.find(t => t.up_to > items)?.unit_amount;
  }
};

export const getFlatAmountPrice = (product: StripeProduct, items: number) => {
  if (product.price.billing_scheme === 'tiered') {
    let tiers = product.price.tiers;
    if (tiers.length === 1) {
      return tiers[0].flat_amount;
    }
    return tiers.find(t => t.up_to > items)?.flat_amount;
  }
};

export const renderMoneyAmount = (value: number | undefined) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (!value || value < 0) {
    return '--';
  } else {
    return `${formatter.format(value.valueOf() / 100)}`;
  }
};

export const currentDateKey = () => {
  const date2 = new Date();
  return String(date2.getMonth() + 1).padStart(2, '0') + '' + date2.getFullYear();
};

export const getYearMonthDateKey = (date: Date) => {
  const key = date.getFullYear() + String( date.getMonth() + 1).padStart(2, '0');
  return key;
};

export const getDateFromYearMonthDateKey = (dateKey: string) => {
  const newDate = new Date(Number(dateKey.substring(0,4)), Number(dateKey.substring(4,6)) -1,1);
  return newDate;
};
export const getFullDateFromYearMonthDateKey = (dateKey: string) => {
  const newDate = new Date(Number(dateKey.substring(6,10)), Number(dateKey.substring(0,2)) -1 ,Number(dateKey.substring(3,5)));
  return newDate;
};

export type BreadCrumbItem = {
  nav: string;
  text: string;
};

export type ClientFormType = {
  crmUrl?: string;
  crmKeyId?: string;
  crmClientSecret?: string;
  clientName?: string;
  subdomain?: string;
  billingEmail?: string;
  phone?: string;
  address?: string;
  website?: string;
  appStoreLink?: string;
  playStoreLink?: string;
  sendgridApiKey?: string;
  emailFrom?: string;
  nameFrom?: string;
  logo?: string;
  icon?: string;
  primaryColor?: string;
  whiteLogo?: string;
  whiteIcon?: string;
  crmUserId?: string;
  clientId?: string;
  isTestClient?: boolean;
};


export type Advance = {
  dealId:string;
  advanceAmount: number;
  balance:string;
  contractDate: string;
  lastPayment:number;
  lastPaymentDate:string;
  status:string;
  fundingDate:string;
  remitPercent:string;

}

export type AdvanceDetails = Advance & AdvanceTransactions;

export type AdvanceTransactions = {
  advanceTransactions: AdvanceTransaction[];

}

export type AdvanceTransaction = {
  advanceTransactionId: string,
  amount: number,
  description: string,
  title: string,
  transactionDate: string,
  transactionType: number
  statusId: string,
  status: string
}
export type IncludeItem = {
  include: boolean
}
export type ReconciliationAdvanceTransaction = AdvanceTransaction & IncludeItem
export type AssignmentType = {
  id?: string;
  key: string;
  isEnabled: boolean;
};

export type AssignmentFilterType = {
  key: string;
  clientId: string;
  isEnabled: boolean;
};

export type DocumentsType = {
  id?: string;
  typeId: string;
  description: string;
};

export type NotesType = {
  id?: string;
  message: string;
};

export type Notes = {
  id?: string;
  noteId: string;
  eventType: string;
  note: string;
  emailsCC: string[];
  userIds: string[];
  isEnabled: boolean;
  modifiedOn: string;
  updatedBy: string;
};

export type NotesParam = {
  clientId: string;
  noteTypeId: string;
  eventType: string;
  emailsCC: string[];
  userIds: string[];
  isEnabled: boolean;
};

export type FeaturesTypeResponse = {
  key: string;
  description: string;
  isEnabled: boolean;
  lastModified: string;
};

export type FeaturesType = {
  clientId: string;
  key: string;
  isEnabled: boolean;
};

export type DocumentFilterType = {
  documentId: string;
  clientId: string;
  isEnabled: boolean;
};

export type PushNotificationType = {
  appId: string;
  appName: string;
  clientId?: string;
  googlePrivateKey: string;
  googleClientEmail: string;
  googleProjectId: string;
  keyId: string;
  token: string;
};

export type ClientInfoType = {
  client: Client | undefined;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type ClientNotificationType = {
  id: string;
  data: any;
  createdOn: string;
  mesasge: string;
  notificationItemStatus: number;
  notificationStatus: number;
  notificationType: number;
  title: string;
};

export const clientMenus = [
  {
    title: 'Client Info',
  },
  {
    title: 'CRM',
  },
  {
    title: 'Assets',
  },
  {
    title: 'Email',
  },
  {
    title: 'Push Notification',
  },
  {
    title: 'Plaid Settings',
  },
  {
    title: 'Advances',
  },
  {
    title: 'Notes',
  },
  {
    title: 'Documents',
  },
  // {
  //   title: 'Assignments',
  // },
];

export type RecentActivityType = {
  eventType: string;
  userId: string;
  clientId: string;
  description: string;
  date: string;
};

export interface IRemittanceReport { 
  deposits: number;
  payments: number;
  statedRemittance: number;
  actualRemittance: number;
  statedHoldback: number;
  RemittanceDelta: number;
}


export const moneyAmount = (value: number | string | undefined, absolute: boolean = false) => {
  let amount = 0;
  if(typeof(value) === 'string'){
    amount = parseFloat(value);
  }
  if(typeof(value) === 'number'){
    amount = value;
  }
  
  if(amount < 0 && absolute)
    amount = amount * -1;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

    return `${formatter.format(amount.valueOf())}`;
};

export const capitalize = (value: string) => {
  if(!value)
    return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
  
};