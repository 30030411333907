import { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaRotate } from 'react-icons/fa6';
import { AccountContext } from '../../services/accountService/accountsProvider';
import transactionsService from '../../services/transactionsService/transactionsService';
import { Account, AccountSummary, formatDate, formatUTCDate, moneyAmount } from '../../types';
import { LoadingSpinnerComponent } from '../Loading';
import { useParams } from 'react-router-dom';

interface IBankAccountDetailsProps {
  bankAccount: Account;
  accountSummary?: AccountSummary;
}

const BankAccountDetails = ({ bankAccount, accountSummary }: IBankAccountDetailsProps) => {  
  const [isLoading, setisLoading] = useState<boolean>(false);
  const params = useParams();
  const handleClick = (itemId: string) => {
    setisLoading(true);
    transactionsService.importTransactionsData(itemId,params.id!).then(r=>{
      setisLoading(false);
    });
  }
    return (
      <>
       {bankAccount.requiresLogin && (
        <Alert variant='warning'>User login is required to access account data. {bankAccount.lastSyncDate && `LastUpdate: ${formatDate(new Date(bankAccount.lastSyncDate))}` }</Alert>
      )}
      {bankAccount && !accountSummary && 
        <Alert variant='warning'>Account does not have data yet
        </Alert>
      }

      { bankAccount && 
        <div style={{ display: 'flex' }} >

            {isLoading && <LoadingSpinnerComponent></LoadingSpinnerComponent>}
            {!isLoading &&
            
            (<>
              <div>
              <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '300', marginBottom: '3px' }}>
               {bankAccount.institutionName} - {bankAccount.name}
              </div>
              <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '300', marginBottom: '3px' }}>
              Account # {bankAccount.number}
              </div>      
              <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '300', marginBottom: '3px' }}>
              Balance {moneyAmount(bankAccount.latestBalance)}
              </div> 
              <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '300', marginBottom: '3px' }}>
              Date added {formatUTCDate(bankAccount.createdOn)}
              </div>
            </div>
            <div style={ {marginLeft: 'auto'}}>
                <div style={{ fontSize: '16px', color: '#2D3142', fontWeight: '400', marginBottom: '3px', marginRight:'5px' }}>
                Last Update {formatUTCDate(bankAccount.lastSyncDate)}
                <Button
                      className="btnNavCustom gray"
                      style={{ marginRight: '5px', paddingLeft: '9px', paddingTop: '3px' }}
                      onClick={() => {
                        handleClick(bankAccount.itemId)
                      }}
                    >
                      <FaRotate style={{ color: '#2D3142' }} />
                    </Button>
                </div>
               
            </div>
              </>
            )
            }
        </div>
      }
      </>
    );
  };
  
  export { BankAccountDetails };
  