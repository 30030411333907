import { createContext, useEffect, useState } from "react";
import {
  Account,
  Advance,
  IRemittanceReport,
  ReconciliationAdvanceTransaction,
  ReconciliationTransaction,
  formatUTCDate,
  getFullDateFromYearMonthDateKey,
  getYearMonthDateKey,
} from "../../../../types";
import { useSearchParams } from "react-router-dom";
import transactionsService from "../../../../services/transactionsService/transactionsService";
import AdvancesService from "../../../../services/crmService/AdvancesService";
import usersService from "../../../../services/usersService/usersService";
interface DateRange {
  start: Date;
  end: Date;
}
interface RemittanceContextType {
  userId?: string;
  contactId?: string;
  clientId?: string;
  accounts?: Array<Account>;
  advances?: Array<Advance>;

  selectedAccounts?: Array<Account>;
  transactions?: Array<ReconciliationTransaction>;
  includeTransaction: (id: string) => void;
  excludeTransaction: (id: string) => void;

  selectedAdvance?: Advance;
  advancePayments?: Array<ReconciliationAdvanceTransaction>;
  includePayment: (id: string) => void;
  excludePayment: (id: string) => void;

  fundingDate: Date;
  lastPaymentDate: Date;
  dateRange: DateRange | undefined;
  setDateRange: (start: Date, end: Date) => void;
  remittanceReport: IRemittanceReport;

  setSelectedAdvance: (advance: Advance) => void;
  setSelectedAccount: (account: Account, view: string) => void;

  isLoading: boolean;
  isCalculatingReport: boolean;
  getAccountName: (accountId: string) => string
}

interface IRemittanceProviderProps {
  clientId: string;
  userId: string;
  contactId: string;
  children: React.ReactNode;
}

const RemittanceContext = createContext<RemittanceContextType>(null!);

function RemittanceProvider({
  children,
  clientId,
  contactId,
  userId,
}: IRemittanceProviderProps) {
  const [selectedAdvance, _setAdvance] = useState<Advance | undefined>(
    undefined
  );
  const [accounts, setAccountsList] = useState<Array<Account> | undefined>(
    undefined
  );
  const [selectedAccounts, _setSelectedAccounts] = useState<Array<Account> | undefined>(
    undefined
  );
  const [remittanceReport, setRemittanceReport] = useState<IRemittanceReport>({
    deposits: 0,
    payments: 0,
    actualRemittance: 0,
    statedRemittance: 0,
    RemittanceDelta: 0,
    statedHoldback: 0,
  });
  const [fundingDate, _setFundingDate] = useState<Date>(new Date());
  const [dateRange, _setDateRange] = useState<DateRange | undefined>(undefined);
  const [lastPaymentDate, _setlastPaymentDate] = useState<Date>(new Date());
  const [isLoading, _setIsLoading] = useState<boolean>(true);
  const [isCalculatingReport, _setIsCalculatingReport] =
    useState<boolean>(false);
  const [advances, setAdvances] = useState<Array<Advance> | undefined>(
    undefined
  );
  const [transactions, _setTransactions] = useState<
    Array<ReconciliationTransaction> | undefined
  >(undefined);

  const [advancePayments, _setAdvancePayments] = useState<
    Array<ReconciliationAdvanceTransaction> | undefined
  >(undefined);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (transactions && fundingDate) {
      updateReport();
      _setIsCalculatingReport(false);
    }
  }, [transactions, dateRange, advancePayments]);

  useEffect(() => {
    _setIsCalculatingReport(true);
    getTransactionsFromFundingDate();
  }, [selectedAccounts, dateRange]);

  useEffect(() => {
    _setIsLoading(true);
    getAdvancesAndAccounts();
  }, [clientId, contactId, userId]);

  const getAdvancesAndAccounts = async () => {
    const plaidItems = transactionsService.getAccounts(userId as string, false);
    const contactAdvances = AdvancesService.getContactAdvances(
      clientId,
      contactId
    );
    const [items, contactAdvancesResult] = await Promise.all([
      plaidItems,
      contactAdvances,
    ]);

    if (items && items.length !== 0) {
      const uniqueBanks = items.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
      const accounts = uniqueBanks
        ?.flatMap((m) => m.accounts)
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
      setAccountsList(accounts);
    }
    if (contactAdvancesResult) {
      setAdvances(contactAdvancesResult);
    }
    _setIsLoading(false);
  };

  const setSelectedAdvance = async (advance: Advance) => {
    _setIsCalculatingReport(true);
    searchParams.set("advanceId", advance.dealId);
    setSearchParams(searchParams);
    _setAdvance({ ...advance });
    const newDate = new Date(advance.fundingDate);
    newDate.setHours(0, 0, 0, 0);
    _setFundingDate(newDate);
    const lastPaymentDate = new Date(advance.lastPaymentDate);
    lastPaymentDate.setHours(0, 0, 0, 0);
    _setlastPaymentDate(new Date(lastPaymentDate));
    _setTransactions(undefined);

    const end = new Date(lastPaymentDate);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);
    const start = new Date(newDate);
    start.setDate(1);

    await setAdavancePayments(advance.dealId, start, end);
    
  };

  const setAdavancePayments = async (dealId: string, startDate: Date, endDate: Date) => {
    const advanceDetails = await AdvancesService.getAdvanceDetails(
      clientId,
      contactId,
      dealId
    );
    if (advanceDetails) {
      const advanceTransactions = advanceDetails?.advanceTransactions.filter(
        (f) => f.statusId === "4"
      ) as ReconciliationAdvanceTransaction[];

      if (advanceTransactions.length > 0) {
        const sorted = advanceTransactions.sort((a, b) =>
          a.transactionDate.localeCompare(b.transactionDate) > 0 ? -1 : 1
        );
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        _setDateRange({ start: startDate, end: endDate});
      const mapTransactions = sorted
        .filter(
          (a) =>
            a.transactionDate &&
            startDate <= new Date(a.transactionDate) &&
            endDate >= new Date(a.transactionDate)
        )
        .map((a) => {
          a.include = true;
          return a;
        });

     
        _setAdvancePayments(mapTransactions);
      }
      else{
        _setDateRange({ start: new Date(), end: new Date() });
        _setAdvancePayments([]);
      }
    }
  }



  const setDateRange = async (start: Date, end: Date) => {
    _setIsCalculatingReport(true);
    const newDateRange = { start: start, end: end };
    if(selectedAdvance){
      await setAdavancePayments(selectedAdvance?.dealId, start, end);
    }
    _setDateRange(newDateRange);
  };

  const setSelectedAccount = async (account: Account, view: string) => {
    _setIsCalculatingReport(true);
    window.history.replaceState(
      {},
      "",
      `/users/${userId}?view=${view}&accountId=${account.id}`
    );
    setSearchParams(searchParams);
    _setTransactions(undefined);
    if(selectedAccounts){
      let newAccounts = [...selectedAccounts];
      if(selectedAccounts?.includes(account)){
       newAccounts = selectedAccounts.filter(f=>f.id!==account.id)
      }
      else{
        newAccounts.push(account);
      }
      if(newAccounts.length ===0){
        _setSelectedAccounts(undefined);
      }
      else{
        _setSelectedAccounts([...newAccounts]);
      }
      return;
    }
    _setSelectedAccounts([account]);
  };

  const excludeTransaction = async (transactionId: string) => {
    if (transactions) {
      const nextTransactions = [...transactions];
      const transactionToHide = nextTransactions.find(
        (t) => t.id === transactionId
      );
      if (transactionToHide) {
        transactionToHide.include = false;
       // transactionToHide.amount *= -1;
        _setTransactions(nextTransactions);
      }
    }
  };
  const getAccountName = (accountId: string) : string => {
    const account = selectedAccounts?.find(a=>a.id === accountId);
    return `${account?.institutionName} ${account?.subType} `
  }
  const includeTransaction = async (transactionId: string) => {
    if (transactions) {
      const nextTransactions = [...transactions];
      const transactionToShow = nextTransactions.find(
        (t) => t.id === transactionId
      );
      if (transactionToShow) {
        transactionToShow.include = true;
        //transactionToShow.amount *= -1;
        _setTransactions(nextTransactions);
      }
    }
  };

  const excludePayment = async (paymentId: string) => {
    if (advancePayments) {
      const nextPayments = [...advancePayments];
      const paymentToHide = nextPayments.find(
        (t) => t.advanceTransactionId === paymentId
      );
      if (paymentToHide) {
        paymentToHide.include = false;
      // paymentToHide.amount *= -1;
        _setAdvancePayments(nextPayments);
      }
    }
  };

  const includePayment = async (paymentId: string) => {
    if (advancePayments) {
      const nextPayments = [...advancePayments];
      const paymentToShow = nextPayments.find(
        (t) => t.advanceTransactionId === paymentId
      );
      if (paymentToShow) {
        paymentToShow.include = true;
       // paymentToShow.amount *= -1;
        _setAdvancePayments(nextPayments);
      }
    }
  };

  const getTransactionsFromFundingDate = async () => {
    if (selectedAccounts) {
      const summaryPromises = selectedAccounts.map(a=>transactionsService.getAccountSummaryList(
        userId,
        a.id
      ))
      const accountSummaries = await Promise.all(summaryPromises!);
      const flatSummaries = accountSummaries.filter(x=> x !== undefined).flat();
      if (flatSummaries && dateRange?.start) {
        const fundingDateKey = getYearMonthDateKey(dateRange?.start);
        const dateKeys = flatSummaries
          ?.filter((a) => a!.dateKey.localeCompare(fundingDateKey) >= 0)
          .flatMap((a) => {return {dateKey: a!.dateKey, accountId: a!.accountId}} );
        
        const promises = dateKeys?.map((d) =>
          transactionsService.getTransactions(userId, d.accountId, clientId, d.dateKey)
        );
        const result = await Promise.all(promises!);
        const flat = result
          .flat()
          .filter((r) => r!.amount < 0) as ReconciliationTransaction[];
        const mapTransactions = flat
          .filter(
            (a) =>
              dateRange &&
              dateRange?.start <= getFullDateFromYearMonthDateKey(a.date) &&
              dateRange?.end >= getFullDateFromYearMonthDateKey(a.date)
          )
          .map((a) => {
            a.include = true;
            return a;
          })
          .sort((a, b) => (a?.date!.localeCompare(b?.date!) >= 0 ? 1 : -1));
        _setTransactions(mapTransactions);
      }
    }
  };

  const updateReport = () => {
    remittanceReport.payments =
      advancePayments
        ?.filter((a) => a.include)
        .filter((a) => a.transactionType === 1)
        .reduce((a, b) => a + b.amount, 0) ?? 0;
    remittanceReport.statedRemittance = Number.parseInt(
      selectedAdvance?.remitPercent ?? "0"
    );
    remittanceReport.deposits =
      transactions
        ?.filter((r) => r.include)
        .reduce((a, b) => a + b.amount * -1, 0) ?? 0;
    if (remittanceReport.deposits > 0 && remittanceReport.payments > 0) {
      remittanceReport.actualRemittance =
        (remittanceReport.payments * 100) / remittanceReport.deposits;
    } else {
      remittanceReport.actualRemittance =
        (remittanceReport.payments * 100) / remittanceReport.deposits ?? 100;
    }

    remittanceReport.statedHoldback =
      remittanceReport.deposits * remittanceReport.statedRemittance * 0.01;
    remittanceReport.RemittanceDelta =
      remittanceReport.deposits * remittanceReport.statedRemittance * 0.01 -
      remittanceReport.payments;
    setRemittanceReport({ ...remittanceReport });
  };

  const value = {
    userId,
    contactId,
    selectedAdvance,
    accounts,
    advances,
    selectedAccounts,
    advancePayments,
    transactions,
    remittanceReport,
    fundingDate,
    clientId,
    setSelectedAdvance,
    setAdvances,
    setSelectedAccount,
    excludeTransaction,
    includeTransaction,
    includePayment,
    excludePayment,
    setDateRange,
    dateRange,
    lastPaymentDate,
    isLoading,
    isCalculatingReport,
    getAccountName
  };

  return (
    <RemittanceContext.Provider value={value}>
      {children}
    </RemittanceContext.Provider>
  );
}

export { RemittanceContext, RemittanceProvider };
