import { createContext, useEffect, useState } from 'react';
import { Account, AccountSummary, BankAccounts, getDateFromYearMonthDateKey, getYearMonthDateKey } from '../../types';
import transactionsService from '../transactionsService/transactionsService';
import usersService from '../usersService/usersService';

interface AccountsContextType {
  userId?: string;
  clientId?: string;
  selectedAccounts?: Array<Account>;
  accounts?: Array<Account>;
  selectedMonth: Date;
  accountSummary?: AccountSummary;
  accountSummaryList?: Array<AccountSummary>;
  loginRequired: boolean;
  hasAccounts: boolean;
  setLoginRequired: (accountId: string, isRequired: boolean) => void;
  setMonth: (month: Date) => void;
  setSelectedAccount: (account: Account, view: string) => void;
  setBankAccounts: (bankAccounts: Array<BankAccounts>, userId: string) => void;
}

interface IAccountProviderProps {
  clientId?: string;
  userId?: string;
  contactId?: string;
  children: React.ReactNode
}

const AccountContext = createContext<AccountsContextType>(null!);

function AccountProvider({ children, clientId, contactId, userId }:  IAccountProviderProps) {
  const [selectedAccounts, setAccount] = useState<Array<Account> | undefined>(undefined);
  const [accounts, setAccountsList] = useState<Array<Account> | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [isLoading, _setIsLoading] = useState<boolean>(true);
  const [accountSummary, setAccountSummary] = useState<AccountSummary | undefined>(undefined);
  const [accountSummaryList, setAccountSummaryList] = useState<Array<AccountSummary> | undefined>(undefined);
  const [loginRequired, _setLoginRequired] = useState<boolean>(false);
  const [hasAccounts, setHasAccounts] = useState<boolean>(false);

  useEffect(()=> {
    _setIsLoading(true);
    getAccounts();
  },[clientId, contactId, userId])

  const getAccounts = async () => {
    if(userId && clientId){
      const response = await transactionsService.getAccounts(userId as string, false);
      
      if (response && response.length !== 0) {
        const uniqueBanks = response?.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
        const accounts = uniqueBanks?.flatMap(m => m.accounts).filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
        setAccountsList(accounts);
        setSelectedAccount(accounts[0],"Financial")
        setHasAccounts(true);
      }   
      else{
        setHasAccounts(false);
      }
      _setIsLoading(false);
    }
  }

  const setSelectedAccount = (account: Account, view: string) => {
    if(userId){
      window.history.replaceState({}, "", `/users/${userId}?view=${view}&accountId=${account.id}`);  
      setAccount([account]);
      getSummaries(userId, account.id);
    }
};

  const setBankAccounts = (bankAccounts: Array<BankAccounts>, userId: string) => {
    const accounts = bankAccounts?.flatMap(m => m.accounts).filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
    if (accounts && accounts.length > 0) {
      setAccounts(accounts, userId);
    }
    else{
     clearContext();
    }
  };

  const setAccounts = (accounts: Array<Account>, userId: string) => {
    const uniqueAccounts = accounts
      .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
      .sort((a,b) =>  (a.requiresLogin === b.requiresLogin) ? 0 : a.requiresLogin ? 1 : -1);
    if (uniqueAccounts.length > 0) {
      setAccountsList(uniqueAccounts);
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('accountId');
      if(myParam){
        const account = uniqueAccounts.find(a=>a.id === myParam)
        if(account){
          setSelectedAccount(account,"Financial");
        }
      }
      else{
        setSelectedAccount(uniqueAccounts[0],"Financial");
      }
    } 
    
  };

  let setLoginRequired = (accountId: string, isRequired: boolean) => {
    if (accounts) {
      let account = accounts.find((a) => a.id === accountId);
      if (account && userId) {
        account.requiresLogin = isRequired;
        setAccountsList(accounts);
      }
    }
  };

  const setMonth = (month: Date) => {
    if (userId && accountSummaryList) {
      setSelectedMonth(month);
      const summary = accountSummaryList.find(s=>s.dateKey === getYearMonthDateKey(month));
      setAccountSummary(summary);
    }
  };

  const getSummaries = (userId: string, accountId: string) => {
    transactionsService.getAccountSummaryList(userId, accountId).then(data => {
      if(data && data.length > 0){
        setAccountSummaryList(data);
        const lastMonth = data.sort((a : AccountSummary, b : AccountSummary) => a.dateKey.localeCompare(b.dateKey) > 0 ? -1 : 1);
        setSelectedMonth(getDateFromYearMonthDateKey(lastMonth[0].dateKey));
        const summary = lastMonth[0];
        setAccountSummary(summary);
      }else{
        setAccountSummary(undefined);
        setAccountSummaryList(undefined);
      }
    });
  };

  const clearContext = () => {
      setAccount(undefined);
      setSelectedMonth(new Date());
      setAccountSummary(undefined);
      setAccountSummaryList(undefined);
  }

  const value = {
    userId,
    clientId,
    selectedAccounts,
    accounts,
    selectedMonth,
    accountSummary,
    accountSummaryList,
    loginRequired,
    hasAccounts,
    setLoginRequired,
    setMonth,
    setSelectedAccount,
    setBankAccounts
  };

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}

export { AccountProvider, AccountContext };
