import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { ContentCard } from '../../components/Card';
import { RemittanceAuditReport } from './AuditReport';


function ReconciliationList() {
  const [search, setSearch] = useState<string>('');

  return (
    <>
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
          <h3 style={{ color: 'white' }}>Reconciliation</h3>
        </div>
        <ContentCard title="All Users" setSearch={setSearch}>
        <RemittanceAuditReport report={{actualRemittance:18.5,deposits:15500,payments:10000, RemittanceDelta: 2,statedHoldback:1000,statedRemittance:10}} />
        </ContentCard>
      </Container>
    </>
  );
}

export { ReconciliationList };
