import { ReactNode, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import coins from '../../assets/image-icons/coins.png';
import coins2 from '../../assets/image-icons/coins2.png';
import seo from '../../assets/image-icons/seo.png';
import topUp from '../../assets/image-icons/top-up.png';
import { IRemittanceReport, moneyAmount } from '../../types';

const StyledListItem = styled(ListGroup.Item)`
  color: #2d3142;
  margin-top: 0em;
  margin-bottom: 0em;
  width: 100%;
  &:nth-of-type(n + 2) {
    border-left: 1px solid rgb(45, 49, 66, 0.1);
  }
`;
const StyledListGroup = styled(ListGroup)`
    display: flex;
    flex-flow: row;
    &:nth-of-type(n + 2) {
    border-top: 1px solid rgb(45, 49, 66, 0.1);
  }
`;

const StyledGroupWrapper = styled.div`
  border-radius: 24px;
  background-color: #f4f5f6;
  margin-bottom: 18px;
`;

const ItemWrapper = styled(Container)`
  padding: 10px;
`;

const Circle = styled.div`
  width: 48px;
  height: 48px;
  background: rgba(26, 134, 203, 0.05);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin: auto;
  display: block;
`;
const NumberText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  color: #2d3142;
  margin-top: 5px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #4c5980;
`;
const ImageIcon = styled.img`
  margin-top: 13px;
`;

interface AuditReportProps {
report: IRemittanceReport;
}

const RemittanceAuditReport = ({ report }: AuditReportProps) => {
  const ItemData = (Icon: ReactNode, title: string, value: string) => {
    return (
      <ItemWrapper>
        <Row>
          <Col className="text-center">
            <Circle className="text-center">{Icon}</Circle>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <NumberText>{value}</NumberText>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Text>{title}</Text>
          </Col>
        </Row>
      </ItemWrapper>
    );
  };

  return (
    <>
        <StyledGroupWrapper>
          <StyledListGroup horizontal>
          <StyledListItem as={Col} md={2.4}>
              {ItemData(
              <ImageIcon src={coins2}></ImageIcon>, 
              'Adjustment Amount',
              `${report.RemittanceDelta > 0 ? '-' :'+' }${moneyAmount(report.RemittanceDelta,true)}`)}
            </StyledListItem>
            <StyledListItem as={Col} md={2.4}>
              {ItemData(
              <ImageIcon src={coins2}></ImageIcon>, 
              'Actual Holdback',
              `${report.actualRemittance.toFixed(2)}%`)}
            </StyledListItem>
            <StyledListItem as={Col} md={2.4}>
              {ItemData(
                <ImageIcon src={coins}></ImageIcon>,
                'Stated Holdback',
                `${moneyAmount(report.statedHoldback)}`
              )}{' '}
            </StyledListItem>
            <StyledListItem as={Col} md={2.4}>
              {ItemData(
                <ImageIcon src={topUp}></ImageIcon>,
                'Total Deposits',
                moneyAmount(report.deposits)
              )}
            </StyledListItem>
            <StyledListItem as={Col} md={2.4}>
              {ItemData(
              <ImageIcon src={seo}></ImageIcon>,
               'Total Payments', 
               moneyAmount(report.payments)
              )}
            </StyledListItem>
          </StyledListGroup>
        </StyledGroupWrapper>
    </>
  );
}

export { RemittanceAuditReport };

