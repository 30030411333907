/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'react-bootstrap';

import { Financial, Notifications, RecentActivity } from '../../components/Users/Detail';
import { CentrexBreadcrumb } from '../../components/Breadcrumb';
import { ContentCard } from '../../components/Card';
import { UserInfo } from '../../components/Users/userInfo';
import { AssetRequestHandle, UserAssetReports } from '../../components/BankAccounts/AssetReports';
import MenuButtons from '../../components/MenuButtons';

import { AccountContext, AccountProvider } from '../../services/accountService/accountsProvider';
import usersService from '../../services/usersService/usersService';

import type { BreadCrumbItem, UserData } from '../../types';
import type { btnOption } from '../../components/MenuButtons';
import Remittance from '../../components/Users/Detail/Remittance/Remittance';
import { RemittanceProvider } from '../../components/Users/Detail/Remittance/RemittanceContext';
import { NotFound } from '../NotFound';
import { AuthContext } from '../../services/authService/authProvider';

const menus: btnOption[] = [
  {
    title: 'Financial',
  },
  {
    title: 'Advance Reconciliation',
  },
  {
    title: 'Notifications',
  },
  {
    title: 'Activity',
  },
];

function UserDetails() {
  const params = useParams();
  const childCompRef = useRef<AssetRequestHandle>(null);
  const authContext = useContext(AuthContext);
  const [navItems, setNavItems] = useState<Array<BreadCrumbItem>>([
    { nav: '/users', text: 'User Management' },
    { nav: '/users/' + params.id, text: 'User Details' },
  ]);
  let [searchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState<UserData | undefined>();
  const [canRequestAssetReport, setCanRequestAssetReport] = useState<boolean>(true);
  const [requestedAssetReport, setRequestedAssetReport] = useState<boolean>(false);
  const [selectView, setSelectView] = useState<string>( searchParams.get("view") || 'Financial');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [found, setFound] = useState<boolean>(true);
  const [sendView, setSendView] = useState<boolean>(false);

  useEffect(() => {
    if (params.id && !loading) {
      (async () => {
        setIsLoading(true);
        try{
          const userDetail = await usersService.getUserProfile(params.id as string, authContext.clientId!);
          setUserDetails(userDetail);
          setNavItems([
            { nav: '/users', text: 'User Management' },
            { nav: '/users/' + params.id, text: userDetail?.userName! },
          ]);
        }
        catch(exception){
          setFound(false);
        }
        setIsLoading(false);
      })();
    }
  }, [params.id, loading]);

  useEffect(() => {
    setSendView(false);
    window.history.replaceState({}, "", `/users/${params.id}?view=${selectView}`)
  }, [selectView, setSelectView]);

  useEffect(() => {
    if(params.view)
      setSelectView(params.view);
  }, [params.view]);

  const handleAssetReportLoad = (canCreate: boolean) => {
    setCanRequestAssetReport(canCreate);
  };

  const handleAssetReportRequested = () => {
    setRequestedAssetReport(true);
  };

  return (
    <Container fluid>
      {!found && (<>
        <NotFound></NotFound>
      </>)}
      {found && 
        <AccountProvider clientId={userDetails?.clientId}  contactId={userDetails?.contactId} userId={params.id}>
          <Row>
            <CentrexBreadcrumb
              items={navItems}
              setCanRequestAssetReport={setCanRequestAssetReport}
              requestedAssetReport={requestedAssetReport}
              canRequestAssetReport={canRequestAssetReport}
              index="user_detail"
              childCompRef={childCompRef}
              userDetails={userDetails}
            />
          </Row>
          <Row>
            <Col xs={12} md={12} lg={4}>
              <ContentCard bodyPadding="0.5em">
                <UserInfo userDetails={userDetails} loading={loading} setLoading={setLoading} />
              </ContentCard>
              <UserAssetReports
                onLoaded={handleAssetReportLoad}
                onRequested={handleAssetReportRequested}
                ref={childCompRef}
              />
            </Col>
            <Col xs={12} md={12} lg={8}>
              <ContentCard bodyPadding="0 1rem 1rem 1rem">
                {userDetails && (
                  <>
                    {userDetails?.userStatus === 4 ? (
                      <>
                        <div style={{ width: '100%', marginBottom: '40px' }}>
                          <MenuButtons titles={menus} selectView={selectView} setSelectView={setSelectView} />
                        </div>

                        <Container fluid>
                          {selectView === 'Financial' && <Financial id={params.id} isLoading={isLoading} />}
                          {selectView === 'Advance Reconciliation' && userDetails.contactId && params.id &&
                          <RemittanceProvider clientId={userDetails.clientId} contactId={userDetails.contactId} userId={params.id}>
                            <Remittance />
                          </RemittanceProvider>}

                          {selectView === 'Notifications' && (
                            <Notifications
                              userId={userDetails?.id as string}
                              clientId={userDetails?.clientId as string}
                              contactId={userDetails?.contactId as string}
                              sendView={sendView}
                              setSendView={setSendView}
                            />
                          )}

                          {selectView === 'Activity' && (
                            <RecentActivity userId={userDetails?.id as string} clientId={userDetails?.clientId as string} />
                          )}
                        </Container>
                      </>
                    ) : (
                      <h1
                        style={{
                          fontSize: '24px',
                          color: '#2d3142',
                          fontWeight: '500',
                          textAlign: 'center',
                          padding: '25px 0',
                        }}
                      >
                        User is deactivated, data is not available
                      </h1>
                    )}
                  </>
                )}
              </ContentCard>
            </Col>
          </Row>
        </AccountProvider>
      }
    </Container>
  );
}

export default UserDetails;
