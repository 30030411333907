import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { format } from 'date-fns';

import { Data, TableNode } from '@table-library/react-table-library';
import { CompactTable } from '@table-library/react-table-library/compact';

import { CentrexBreadcrumb } from '../../components/Breadcrumb';
import { ContentCard } from '../../components/Card';
import { LoadingSpinnerComponent } from '../../components/Loading';
import transactionsService from '../../services/transactionsService/transactionsService';
import { BankAccounts, BreadCrumbItem } from '../../types';
import PlaidLinkWithOAuth from '../PlaidLink/plaidLink';

import { FaTrash } from 'react-icons/fa';
import PlaidLinkUpdate from '../PlaidLink/plaidLinkUpdate';
import PlaidLinkUpdateButton from '../PlaidLink/plaidLinkUpdate';

function UserBankAccounts() {
  const params = useParams();

  const navItems: BreadCrumbItem[] = [
    { nav: '/users', text: 'User Management' },
    { nav: '/users/' + params.id, text: 'User Details' },
    { nav: '', text: 'Bank Accounts' },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bankAccounts, setBankAccounts] = useState<Array<BankAccounts> | undefined>(undefined);
  const [show, setShow] = useState<BankAccounts | undefined>(undefined);
  const [tableData, setTableData] = useState<Data<TableNode>>({
    nodes: [],
  });

  const handleClose = () => setShow(undefined);
  const handleRemove = (itemId: string) => removeAccount(itemId);

  const fetchData = useCallback(async () => {
    if (params.id) {
      setIsLoading(true);
      transactionsService.getAccounts(params.id, true).then(result => {
        if (result && result?.length > 0) {
          const uniqueBanks = result.sort((a, b) =>
            a.removedOn === null ? -1 : a.removedOn.localeCompare(b.removedOn)
          );
          setBankAccounts(uniqueBanks);
          setTableData({ nodes: uniqueBanks });
        }
        setIsLoading(false);
      });
    }
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const removeAccount = (itemId: string) => {
    setIsLoading(true);
    transactionsService.removePlaidItem(params.id!,itemId).then(result => {
      setShow(undefined);
      let items = bankAccounts?.map(i => {
        if (i.itemId === itemId) i.removedOn = new Date().toISOString();
        return i;
      });
      setBankAccounts(items);
      setIsLoading(false);
    });
  };

  const COLUMNS = [
    { label: 'Bank', renderCell: (item: any) => item.institutionName },
    { label: 'Created', renderCell: (item: any) => format(new Date(item.createdOn), 'MM/dd/yyyy') },
    { label: 'Removed', renderCell: (item: any) => item.removedOn ? format(new Date(item.removedOn), 'MM/dd/yyyy'): '' },
    { label: 'Accounts', renderCell: (item: any) => item.accounts.length },
    {
      label: '',
      renderCell: (item: any) => (
        <>
          {!item.removedOn && (
            <PlaidLinkUpdateButton onComplete={fetchData} itemId={item.itemId} userId={params.id!}  />
          )}
        </>
      ),
    },
    {
      label: '',
      renderCell: (item: any) => (
        <>
          {!item.removedOn && (
            <Button onClick={() => setShow(item)}>
              <FaTrash></FaTrash>
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <CentrexBreadcrumb items={navItems} setCanRequestAssetReport={() => {}} />
          <Col xs={12} md={12} lg={8}>
            <ContentCard>
              {isLoading && <LoadingSpinnerComponent></LoadingSpinnerComponent>}
              <PlaidLinkWithOAuth onComplete={fetchData} />
              {!isLoading && !bankAccounts && <Alert>This user does not have accounts linked </Alert>}

              {!isLoading && bankAccounts && (
                <>
                  <CompactTable columns={COLUMNS} data={tableData} />
                </>
              )}
            </ContentCard>
          </Col>
        </Row>
      </Container>
      <Modal show={show !== undefined} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove bank connection</Modal.Title>
        </Modal.Header>
        {show && (
          <>
            <Modal.Body>Confirm {show?.institutionName} removal </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => handleRemove(show?.itemId)}>
                Remove
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export { UserBankAccounts };
