import { format } from 'date-fns';
import { AccountSummary, BankAccounts, GetLinkTokenResponse, ProcessLinkRequest, Transaction } from '../../types';
import { axiosPrivate } from '../axios/axiosPrivate';
import { AxiosError } from 'axios';
class TransactionsService {
  async getAccounts(
    id: string,
    includeDeleted: boolean = false
  ): Promise<Array<BankAccounts> | undefined> {
    try {
      const response: any = await axiosPrivate.get(
        `/transactions/api/BankAccount/AllByUserId?userId=${id}&includeRemoved=${includeDeleted}`,
        {
          cache: false,
        }
      );

      if (response?.status === 200) {
        await axiosPrivate.storage.remove(response.requestId);

        return response?.data?.map((u: any) => ({
          id: u.itemId,
          clientId: u.clientId,
          institutionId: u.institutionId,
          institutionLogo: u.institutionLogo,
          institutionName: u.institutionName,
          itemId: u.itemId,
          requireAuth: u.requireAuth,
          removedOn: u.removedOn,
          accessToken: u.accessToken,
          createdOn: u.createdOn,
          userId: u.userId,
          accounts: u?.accounts?.map((a: any) => ({
            id: a.accountId,
            name: a.name,
            number: a.accountNumber,
            type: a.accountType,
            subType: a.accountSubtype,
            institutionName: u.institutionName,
            bankId: u.id,
            createdOn: u.createdOn,
            institutionId: u.institutionId,
            latestBalance: a.balance,
            itemId: u.itemId,
            requiresLogin: u.requireAuth,
          })),
        }));
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getTransactions(userId: string, accountId: string, clientId: string, dateKey: string): Promise<Array<Transaction> | undefined> {
    try {
      const response = await axiosPrivate.post('/transactions/api/Transaction/GetTransactions', {
        userId: userId,
        clientId: clientId,
        accountId: accountId,
        dateKey: dateKey
      });

      if (response.status === 200) {
        const transactions: Array<Transaction> = response.data.map(
          (t: any) =>
            ({
              id: t.id,
              amount: t.amount,
              date: format(t.datetime as Date, 'MM-dd-yyyy'),
              description: t.description,
              account: accountId
            } as Transaction)
        );
        return transactions;
      } 
    }
    catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getAccountSummary(userId: string, accountId: string, month: Date): Promise<AccountSummary | undefined> {
    try {
      const from = new Date(month.getUTCFullYear(), month.getMonth(), 1).toISOString().split('T')[0];
      const to = new Date(month.getUTCFullYear(), month.getMonth(), 1).toISOString().split('T')[0];

      const response = await axiosPrivate.get(
        `transactions/api/BankAccount/GetSummary?UserId=${userId}&AccountId=${accountId}&StartDate=${from}&EndDate=${to}`
      );

      if (response?.status === 200) {
        const s = response?.data?.financialsSummary?.[0];

        return {
          accountId: accountId,
          totalDeposits: s?.totalDeposits,
          avgBalance: s?.avgBalance,
          withdrawals: s?.withdrawals,
          startingBalance: s?.startingBalance,
          endBalance: s?.endBalance,
          noDeposits: s?.noDeposits,
          daysNegative: s?.daysNegative,
          nsf: s?.nsf,
          balanceDays: s?.balanceDays,
          dateKey: s?.dateKey
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getAccountSummaryList(userId: string, accountId: string): Promise<Array<AccountSummary> | undefined> {
    try {
      const response: any = await axiosPrivate.get(
        `transactions/api/BankAccount/GetSummaryList?UserId=${userId}&AccountId=${accountId}`,
        {
          cache: false
        }
      );

      if (response.status === 200) {
        const summaryList : Array<AccountSummary> = response.data.map((s:any)=>({
            accountId: accountId,
            totalDeposits: s.totalDeposits,
            avgBalance: s.avgBalance,
            withdrawals: s.withdrawals,
            startingBalance: s.startingBalance,
            endBalance: s.endBalance,
            noDeposits: s.noDeposits,
            daysNegative: s.daysNegative,
            nsf: s.nsf,
            balanceDays: s.balanceHistory,
            dateKey: s.dateKey
          }) as AccountSummary);
          return summaryList;
        }
        return undefined;
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getAccountMonthlyBalance(userId: string, accountId: string, month: Date): Promise<AccountSummary | undefined> {
    try {
      const from = new Date(month.getUTCFullYear(), month.getMonth(), 1).toISOString().split('T')[0];
      const to = new Date(month.getUTCFullYear(), month.getMonth(), 1).toISOString().split('T')[0];
      const response = await axiosPrivate.post('transactions/api/DashboardFinancial/GetBalanceByMonthByUser', {
        userId: userId,
        accountId: accountId,
        startDate: from,
        endDate: to,
      });

      if (response?.status === 200) {
        const t = response?.data?.financialSummary;

        return {
          totalDeposits: t.totalDeposits,
          avgBalance: t.avgBalance,
          withdrawals: t.withdrawals,
          startingBalance: t.startingBalance,
          endBalance: t.endBalance,
          noDeposits: t.noDeposits,
          daysNegative: t.daysNegative,
          nsf: t.nsf,
        } as AccountSummary;
      }
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async getAssetReports(userId: string, forceRefresh: boolean = false) {
    try {
      const response: any = await axiosPrivate.get(`transactions/api/AssetReport/get?userId=${userId}`, {
        cache: false,
      });
      if (response?.status === 200) {
        return response?.data?.map((t: any) => ({
          clientId: t.clientId,
          userId: t.userId,
          contactId: t.contactId,
          requestDate: t.requestDate,
          status: t.status,
          assetReportToken: t.assetReportToken,
          assetReportId: t.assetReportId,
          id: t.assetReportId,
        }));
      }

      await axiosPrivate.storage.remove(response?.requestId);
    } catch (err) {
      return Promise.reject(err);
    }

    return undefined;
  }

  async createAssetReport(userId: string) {
    try {
      const response = await axiosPrivate.post(`transactions/api/AssetReport/create?userId=${userId}`, {
        userId: userId,
      });

      if (response?.status === 200) {
        return response;
      }
    } catch (err: any) {
      if (typeof err === typeof AxiosError) return Promise.reject(err.code);
      return Promise.reject(err);
    }

    return undefined;
  }

  async createPlaidLink(userId: string, clientId: string): Promise<GetLinkTokenResponse | undefined> {
    try {
      return await (
        await axiosPrivate.get(`transactions/api/PlaidLink/getToken?userId=${userId}&clientId=${clientId}`)
      ).data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updatePlaidLink(userId: string, clientId: string,itemId: string): Promise<GetLinkTokenResponse | undefined> {
    try {
      return await (
        await axiosPrivate.get(`transactions/api/PlaidLink/getToken?userId=${userId}&clientId=${clientId}&itemId=${itemId}`)
      ).data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updatePlaidItem(userId: string, itemId: string): Promise<GetLinkTokenResponse | undefined> {
    try {
      return await (
        await axiosPrivate.post(`transactions/api/plaid-link/update-item`,
          {
            userId:userId,
            itemId:itemId
        })
      ).data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async importTransactionsData(itemId:string, userId: string){
    const sendSummaryNote: Boolean = false; 
    const request = {
      sendSummaryNote,
      forceRefresh : true,
      historicalUpdateComplete: true,
      userId,
      itemId
    }
    try {
      return await axiosPrivate.post(`transactions/api/import/transactions`, request);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async processPlaidAccessToken(request: ProcessLinkRequest) {
    try {
      return await axiosPrivate.post(`transactions/api/PlaidLink/processAccessToken`, request);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async addPlaidItem(userId: string) {
    try {
      return axiosPrivate.post(`transactions/api/AssetReport/create?userId=${userId}`, {
        userId: userId,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async removePlaidItem(userId:string, itemId: string) {
    try {
      return axiosPrivate.delete(`transactions/api/BankAccount/Item?itemId=${itemId}&userId=${userId}`);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async sendPushNotification(clientId: string, title: string, description: string): Promise<any> {
    try {
      const pushNotifications = {
        ClientId: clientId,
        Title: title,
        Description: description,
      };
      const response = await axiosPrivate.post('transactions/api/PushNotificationSender/Send', pushNotifications);

      if (response?.status === 200) {
        return response;
      }
    } catch (err: any) {
      if (err?.response && err?.response?.status === 400) {
        if (err?.response?.data?.errors && typeof err?.response?.data?.errors === 'object') {
          const errors = err?.response?.data?.errors;
          return Promise.reject(errors);
        }
      }
    }

    return undefined;
  }

  async sendClientNotification(data: { title: string; message: string; contactId: string }): Promise<any> {
    try {
      const response = await axiosPrivate.post('transactions/api/PushNotification/SendContact', data);

      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return undefined;
  }
}

export default new TransactionsService();
