/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { AccountContext } from '../../services/accountService/accountsProvider';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  ChartData,
  ScatterDataPoint,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendar } from 'react-icons/fa';
import '../custom.css';
import { getDateFromYearMonthDateKey } from '../../types';
import { getDay} from "date-fns";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip);

const Wrapper = styled.div`
  height: 320px;
  margin-bottom: 3em;
`;

const getDaysInMonth = (year: number, month: number) => {
  var date = new Date(year, month, 1);
  var days = [];

  while (date.getMonth() === month) {
    days.push(new Date(date).getDate());
    date.setDate(date.getDate() + 1);
  }

  return days;
};

function AccountMonthBalance() {
  const accountContext = useContext(AccountContext);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ChartData<'line', (number | ScatterDataPoint | null)[], unknown>>();

  useEffect(() => {
    if (accountContext?.accountSummary && accountContext?.accountSummary?.balanceDays?.length > 0) {
      const dailyValues = accountContext?.accountSummary?.balanceDays?.sort(
        (a, b) => b.dateTime.localeCompare(a.dateTime)
      );

      const data: ChartData<'line', (number | ScatterDataPoint | null)[], unknown> = {
        labels: getDaysInMonth(accountContext?.selectedMonth?.getFullYear(), accountContext?.selectedMonth?.getMonth()),
        datasets: [
          {
            label: '$',
            data: dailyValues.map(s => {
              return { y: s.balance, x: new Date(s.dateTime).getDate() };
            }),
            borderColor: '#10BCD2',
            backgroundColor: 'rgba(78, 115, 223, 0.05)',
            tension: 0.4,
            fill: false,
          },
        ],
      };

      setChartData(data);
    } else {
      setChartData(undefined);
    }

    setIsLoaded(true);
  }, [accountContext.accountSummary, accountContext.selectedMonth]);

  const handleMonthChange = (date: Date) => {
    //setSelectedMonth(date);
    accountContext.setMonth(date);
  };

  return (
    <>
      <Row>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
          <h4>Spending Report</h4>
          <div style={{ width: '150px', position: 'relative' }}>
            <DatePicker
              className="form-control"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              includeDates={accountContext.accountSummaryList?.map(a=> getDateFromYearMonthDateKey(a.dateKey))}
              showDisabledMonthNavigation
              maxDate={new Date()}
              selected={accountContext.selectedMonth}
              onChange={date => handleMonthChange(date!)}
            />
            <FaRegCalendar style={{ position: 'absolute', top: '10px', right: '10px' }} />
          </div>
        </div>
      </Row>
      {!isLoaded && chartData && (
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Spinner animation="border" role="status" variant="primary" style={{ width: '4rem', height: '4rem' }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Container>
      )}
      {isLoaded && !chartData && (
        <Row className="justify-content-md-center">
          <Col md="12">
            <Alert>Spending report is not available for this account and month</Alert>
          </Col>
        </Row>
      )}
      {chartData && (
        <Wrapper>
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Wrapper>
      )}
    </>
  );
}

export { AccountMonthBalance };
