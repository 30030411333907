/* eslint-disable react-hooks/exhaustive-deps */

import { ReactNode, RefAttributes, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Col, Container, Row as BRow, InputGroup, Button, Form, OverlayTrigger, TooltipProps, Tooltip } from 'react-bootstrap';
import { format } from 'date-fns';

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
  Data,
  TableNode,
  HeaderCell,
} from '@table-library/react-table-library/table';
import { usePagination } from '@table-library/react-table-library/pagination';
import { HeaderCellSort, useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import styled from 'styled-components';

import { AuthContext } from '../../services/authService/authProvider';
import usersService from '../../services/usersService/usersService';
import { User, TableItem } from '../../types';
import { StatusBadge } from '../../components/Badges/StatusBadge';
import { ProfileImage } from '../../components/ProfileImage';
import { LoadingSpinnerComponent } from '../../components/Loading';

import { IoMdWarning } from 'react-icons/io';
import { FaChevronDown, FaChevronUp, FaWordpress, FaMobile } from 'react-icons/fa';
import { FaPlay, FaStepBackward, FaStepForward } from 'react-icons/fa';
import { Action, State } from '@table-library/react-table-library/types/common';

type UsersTableProps = {
  rows: number;
  hiddenColumns?: Array<string>;
  search?: string;
};

const StyledHeaderCell = styled(HeaderCellSort)`
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
  & span {
    color: red;
    margin-left: 8px !important;
    height: 12px;
    width: 12px;
  }
`;

const StyledEmail = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4c5980;
`;

const StyledGroupText = styled(InputGroup.Text)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
`;
const StyledGroupButton = styled(Button)`
  border: none;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  color: #4c5980;
  padding: 5px;
  &.btn:disabled {
    background-color: transparent;
    color: #4c5980;
  }
`;

const Badge = (status: number): ReactNode => {
  switch (status) {
    case 0:
      return <StatusBadge type="danger">Deleted</StatusBadge>;
    case 2:
      return <StatusBadge>Inactive</StatusBadge>;
    case 4:
      return <StatusBadge type="success">Active</StatusBadge>;
  }
};

const getUserAuthRequired = (authRequired: boolean) => {
  return authRequired ? <IoMdWarning color="#FFCC00" /> : null;
};

const UsersTable = ({ rows, hiddenColumns = [], search }: UsersTableProps) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [userList, setUserList] = useState<Array<TableItem<User>> | undefined>();
  const [tableLoaded, setTableLoaded] = useState<boolean | undefined>(false);
  const [tableData, setTableData] = useState<Data<TableNode>>({ nodes: [] });
  const [perPage, setPerPage] = useState<number>(rows);

  const theme = useTheme({
    Table: `
        --data-table-library_grid-template-columns: minmax(200px, max-content) minmax(200px, 100%) repeat(${
          4 - hiddenColumns.length
        }, minmax(100px, 100%));
      `,
    BaseCell: `
          &:nth-of-type(1) {
            left: 0px;
          }
  
          &:nth-of-type(2) {
            left: 0px;
          }
          font-size:14px;
        `,
    Row: `
        cursor: pointer;
        color: rgb(117, 117, 117);
        &:hover {
          color: black;
        }
        &:hover .td {
          background-color:#f8f9fa;
        }
        `,
  });

  useEffect(() => {
    if (auth.clientId && auth.clientId !== '0') {
      usersService.getUsersList(auth.clientId).then(result => {
        if (result) {
          const tableItems = result?.map((r, i) => ({ ...r, number: i + 1 } as TableItem<User>));
          setUserList(tableItems);
          const tableData = { nodes: tableItems } as Data<TableNode>;
          setTableData(tableData);
        }
        setTableLoaded(true);
      });
    } else {
      setTableLoaded(true);
    }
  }, [auth]);

  useEffect(() => {
    if (userList) {
      const searchLower = search?.toLowerCase();

      if (searchLower) {
        const filteredData = {
          nodes: userList.filter(f => f.userName.toLowerCase().includes(searchLower) || f.email.includes(searchLower) || f.contactId === searchLower ),
        } as Data<TableNode>;

        if (filteredData && filteredData.nodes.length > 0) {
          setTableData(filteredData);
        }

        pagination.fns.onSetPage(0);
      } else {
        const tableData = { nodes: userList } as Data<TableNode>;
        setTableData(tableData);
      }
    }
  }, [search, userList]);

  const pagination = usePagination(tableData, {
    state: {
      page: 0,
      size: perPage,
    },
  });

  const sort = useSort(
    tableData,
    {
      onChange: (action: Action, state: State, context: any) => {
        window.history.pushState({}, "", `users?SortBy=${state.sortKey}&reverse=${state.reverse}`);
      },
      state:{
        sortKey: searchParams.get("SortBy") || "CREATED",
        reverse: searchParams.get("reverse") === 'false'
      }

    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <FaChevronUp style={{ color: '#4C5980' }} />,
        iconDown: <FaChevronDown style={{ color: '#4C5980' }} />,
      },
      sortFns: {
        USERNAME: array => array.sort((a,b) => a.email.localeCompare(b.email)),
        CONNECTIONS: array => array.sort((a, b) => (!a.plaidAuthRequired && b.plaidAuthRequired) || a.plaidConnections > b.plaidConnections ? 1 : -1),
        CREATED: array => array.sort((a, b) => b.createdDate.localeCompare(a.createdDate)),
        BUSINESS: array => array.sort((a,b) => !a.company ? -1 : a.company.localeCompare(b.company)),
        ACCESS: array => array.sort((a,b) =>  a.wppUser > b.wppUser ? 1 : -1 ),
        STATUS: array => array.sort((a,b) =>  a.userStatus > b.userStatus ? 1 : -1 ),
      },

    }
  );

  return (
    <>
      {!tableLoaded && <LoadingSpinnerComponent />}

      {tableLoaded && tableData.nodes.length === 0 && (
        <Container>
          <BRow className="justify-content-md-center">
            <Col md="auto">
              <span>Data not available</span>
            </Col>
          </BRow>
        </Container>
      )}

      {tableLoaded && tableData.nodes.length > 0 && (
        <>
          <Table
            data={tableData}
            pagination={pagination}
            sort={sort}
            theme={theme}
            layout={{ custom: true, horizontalScroll: true }}
          >
            {(tableList: TableNode[]) => (
              <>
                <Header>
                  <HeaderRow>
                    <StyledHeaderCell sortKey={'USERNAME'} >User</StyledHeaderCell>
                    <StyledHeaderCell sortKey={'BUSINESS'}>Business Name</StyledHeaderCell>
                    <StyledHeaderCell sortKey={'CREATED'} hide={hiddenColumns?.includes('CREATED')}>
                      Created Date
                    </StyledHeaderCell>
                    <StyledHeaderCell sortKey={'ACCESS'} hide={hiddenColumns?.includes('ACCESS')}>Access Type</StyledHeaderCell>
                    <StyledHeaderCell sortKey={'CONNECTIONS'} hide={hiddenColumns?.includes('CONNECTIONS')}>Plaid Connections</StyledHeaderCell>
                    <StyledHeaderCell sortKey={'STATUS'} hide={hiddenColumns?.includes('STATUS')}>Status</StyledHeaderCell> 
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item: TableNode, key: number) => (
                    <Row item={item} key={key} onClick={e => navigate(`/users/${item.id}`)}>
                      <Cell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ProfileImage
                            url={item.profileImageURL}
                            sizePx={30}
                            {...(item?.profileImage ? { urlPrefix: true } : {})}
                          />
                          <div style={{ marginLeft: '8px' }}>
                            {item.userName}
                            <br />
                            <StyledEmail> {item.email}</StyledEmail>
                          </div>
                        </div>
                      </Cell>
                      <Cell>{item.company}</Cell>
                      <Cell hide={hiddenColumns?.includes('CREATED')}>
                        {format(new Date(item.createdDate), 'MM/dd/yyyy')}
                      </Cell>
                      <Cell>
                        {item.wppUser ?  
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={ <Tooltip id="button-tooltip">Word press plugin user</Tooltip>}>
                              <div style={{'display':'inline'}}>
                              <FaWordpress color='#21759b' />
                              </div>
                        </OverlayTrigger> :
                        <></>} {item.mobileApp ?<OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={ <Tooltip id="button-tooltip">Mobile app</Tooltip>}>
                            <div style={{'display':'inline'}}>
                            <FaMobile color='#228B22' />
                            </div>
                      </OverlayTrigger> :  <></>}</Cell>
                      <Cell hide={hiddenColumns?.includes('CONNECTIONS')}>
                        {item.plaidConnections} {getUserAuthRequired(item.plaidAuthRequired)}
                      </Cell>
                      <Cell hide={hiddenColumns?.includes('STATUS')}>{Badge(item.userStatus)}</Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}

      {tableLoaded && tableData.nodes.length > 0 && (
        <Container fluid>
          <BRow style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col md={3} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
              {tableData.nodes.length} items in total
            </Col>
            <Col md={9} xs={7}>
              <InputGroup className="justify-content-end">
                <div style={{ width: '270px' }}>
                  <Form.Select
                    defaultValue={perPage}
                    style={{ outline: 'none', boxShadow: 'none', border: 'none' }}
                    onChange={e => setPerPage(Number(e.target.value))}
                  >
                    <option value="10">Showing 10 items per page</option>
                    <option value="20">Showing 20 items per page</option>
                    <option value="50">Showing 50 items per page</option>
                    <option value="100">Showing 100 items per page</option>
                  </Form.Select>
                </div>
                <StyledGroupButton disabled={pagination.state.page === 0} onClick={() => pagination.fns.onSetPage(0)}>
                  <FaStepBackward></FaStepBackward>
                </StyledGroupButton>
                <StyledGroupButton
                  disabled={pagination.state.page === 0}
                  onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                >
                  <FaPlay style={{ transform: 'rotate(180deg)' }}></FaPlay>
                </StyledGroupButton>
                <StyledGroupText>
                  {pagination.state.getPageBoundaries(tableData.nodes).start}
                  {'-'}
                  {pagination.state.getPageBoundaries(tableData.nodes).end}
                </StyledGroupText>
                <StyledGroupButton
                  disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                  onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                >
                  <FaPlay></FaPlay>
                </StyledGroupButton>
                <StyledGroupButton
                  disabled={pagination.state.page + 1 === pagination.state.getTotalPages(tableData.nodes)}
                  onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(tableData.nodes) - 1)}
                >
                  <FaStepForward></FaStepForward>
                </StyledGroupButton>
              </InputGroup>
            </Col>
          </BRow>
        </Container>
      )}
    </>
  );
};

export { UsersTable };
