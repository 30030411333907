import { useContext, useEffect, useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../services/authService/authProvider';
import DashboardIcon from '../../assets/image-icons/dashboard.png';
import UserIcon from '../../assets/image-icons/user.png';
import BillingIcon from '../../assets/image-icons/invoice.png';
import AdminIcon from '../../assets/image-icons/admin.png';
import SettingIcon from '../../assets/image-icons/setting.png';

const StyledNav = styled(Nav)`
  display: flex;
  flex-direction: column;

  .nav-link {
    color: white;
  }

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: row;
    background: rgb(76 97 129 / 50%);
    border-radius: 25px;
    padding: 2px;
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
`;
const LeftNav = (props: any) => {
  const { id, ...newprops } = props;
  return <StyledNav {...newprops} />;
};

function StyledLeftNav() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  let auth = useContext(AuthContext);

  const [selected, setSelected] = useState(pathname.split('/')[1]);

  useEffect(() => {
    const path = pathname.split('/')[1];

    if (path !== '') {
      setSelected(path);
    } else {
      setSelected('dashboard');
    }
  }, [pathname, auth, auth.is2FAEnabled]);

  return (
    <>
      {auth.is2FAEnabled && 
        <LeftNav defaultActiveKey="/">
          <Nav.Link onClick={e => navigate('/', { replace: true })}>
            <div className="leftNavItem">
              <Button className={selected === 'dashboard' ? `btnLeftNav active` : `btnLeftNav`}>
                <img src={DashboardIcon} alt="" />
              </Button>
              <span className={selected === 'dashboard' ? `leftNavTitle active` : `leftNavTitle`}>Dashboard</span>
            </div>
          </Nav.Link>
          <Nav.Link onClick={e => navigate('/users', { replace: true })}>
            <div className="leftNavItem">
              <Button className={selected === 'users' ? `btnLeftNav active` : `btnLeftNav`}>
                <img src={UserIcon} alt="" />
              </Button>
              <span className={selected === 'users' ? `leftNavTitle active` : `leftNavTitle`}>Users</span>
            </div>
          </Nav.Link>
{/*           <Nav.Link onClick={e => navigate('/reconciliation', { replace: true })}>
            <div className="leftNavItem">
              <Button className={selected === 'reconciliation' ? `btnLeftNav active` : `btnLeftNav`}>
                <img src={UserIcon} alt="" />
              </Button>
              <span className={selected === 'reconciliation' ? `leftNavTitle active` : `leftNavTitle`}>Reconciliation</span>
            </div>
          </Nav.Link> */}
          <Nav.Link onClick={e => navigate('/billing', { replace: true })}>
            <div className="leftNavItem">
              <Button className={selected === 'billing' ? `btnLeftNav active` : `btnLeftNav`}>
                <img src={BillingIcon} alt="" />
              </Button>
              <span className={selected === 'billing' ? `leftNavTitle active` : `leftNavTitle`}>Billing</span>
            </div>
          </Nav.Link>

          {auth.isAdmin && (
            <Nav.Link onClick={e => navigate('/admin', { replace: true })}>
              <div className="leftNavItem">
                <Button className={selected === 'admin' ? `btnLeftNav active` : `btnLeftNav`}>
                  <img src={AdminIcon} alt="" />
                </Button>
                <span className={selected === 'admin' ? `leftNavTitle active` : `leftNavTitle`}>Admin</span>
              </div>
            </Nav.Link>
          )}

          {!auth.isAdmin && (
            <Nav.Link onClick={e => navigate('/settings', { replace: true })}>
              <div className="leftNavItem">
                <Button className={selected === 'settings' ? `btnLeftNav active` : `btnLeftNav`}>
                  <img src={SettingIcon} alt="" />
                </Button>
                <span className={selected === 'settings' ? `leftNavTitle active` : `leftNavTitle`}>Settings</span>
              </div>
            </Nav.Link>
          )}
        </LeftNav>
      }
    </>
  );
}

export { StyledLeftNav as LeftNav };
