/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Container, Row as BRow, Spinner, InputGroup, Form } from 'react-bootstrap';
import { AccountContext } from '../../services/accountService/accountsProvider';
import plaidService from '../../services/plaidService/plaidService';
import transactionsService from '../../services/transactionsService/transactionsService';
import { Transaction, PlaidError, getYearMonthDateKey } from '../../types';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormInput } from '../Forms/FormInput';
import { FaSearch } from 'react-icons/fa';
import Steps from '@uiw/react-steps';
import PayrollIcon from '../../assets/image-icons/payroll.png';

const StyledGroupIcon = styled(InputGroup.Text)`
  border: 1px solid #dedede;
  border-width: 0 0 1px;
  border-radius: 0;
  background-color: transparent;
`;

interface IAccountTransactionsProps {
  filter?:  'Debits' | 'Credits';
  search?: Boolean;
}

const AccountTransactions= ({ filter }: IAccountTransactionsProps) => {
  const accountContext = useContext(AccountContext);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [groupDate, setGroupDate] = useState<string[]>([]);
  const [data, setData] = useState<Record<string, Transaction[]> | undefined>(undefined);
  const [allData, setAllData] = useState<Transaction[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (accountContext.selectedAccounts && accountContext.userId && accountContext.selectedMonth && accountContext.clientId) {
      transactionsService
        .getTransactions(accountContext.userId, accountContext.selectedAccounts[0].id, accountContext.clientId, getYearMonthDateKey(accountContext.selectedMonth))
        .then(t => {
          if (t) {
            if(filter){
              t = t.filter(r=> filter ==="Credits" ? r.amount > 0  : r.amount < 0)
            }
            setAllData(t);

            const groupByDate = t.reduce<Record<string, Transaction[]>>((acc, obj) => {
              const { date } = obj;
              acc[date] = acc[date] ?? [];
              acc[date].push(obj);

              return acc;
            }, {});

            setGroupDate(Object.keys(groupByDate));
            setData(groupByDate);
          }
        })
        .catch(() => {});
      setLoaded(true);
    } else {
      setLoaded(true);
    }

    setSearch('');
  }, [accountContext.selectedAccounts, accountContext.selectedMonth]);

  const renderMoneyAmount = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    if (value < 0) {
      return <span className="txtPrice green">{formatter.format(value.valueOf()).replace('-', '')}</span>;
    } else {
      return <span className="txtPrice">{formatter.format(value.valueOf())}</span>;
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    const filter = allData?.filter(
      (rs: Transaction) => rs.description.toUpperCase().search(e.target.value.toUpperCase()) !== -1
    );

    const groupByDate = filter.reduce<Record<string, Transaction[]>>((acc, obj) => {
      const { date } = obj;
      acc[date] = acc[date] ?? [];
      acc[date].push(obj);

      return acc;
    }, {});

    setGroupDate(Object.keys(groupByDate));
    setData(groupByDate);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '24px' }}>
        <h4 style={{ fontSize: '20px' }}>Transaction History</h4>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputGroup style={{ width: '300px', marginRight: '10px' }}>
            <Form.Control as={FormInput} type="search" placeholder="Search" value={search} onChange={handleSearch} />
            <StyledGroupIcon>
              <FaSearch></FaSearch>
            </StyledGroupIcon>
          </InputGroup>
        </div>
      </div>

      {!loaded && (
        <Container>
          <BRow className="justify-content-md-center">
            <Col md="auto">
              <Spinner animation="border" role="status" variant="primary" style={{ width: '4rem', height: '4rem' }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </BRow>
        </Container>
      )}

      {data && loaded && (
        <>
          <Steps direction="vertical">
            {groupDate?.map((date: string, key: number) => (
              <Steps.Step
                key={key}
                title={date}
                description={
                  <>
                    {data?.[date]?.map((rs: Transaction, index: number) => (
                      <div className="transactionStep" key={index}>
                        <div className="item">
                          <img src={PayrollIcon} className="icon" alt="" />
                          <div className="desc">
                            <div className="txt1">{rs.description}</div>
                          </div>
                        </div>
                        <div>{renderMoneyAmount(rs.amount)}</div>
                      </div>
                    ))}
                  </>
                }
              />
            ))}
          </Steps>
        </>
      )}
    </>
  );
}

export { AccountTransactions };
